import { pick } from '../utils/helper';
import Organization from './Organization';
import Property from './Property';
import Location from './Location';

export default interface Vehicle {
    __typename: 'Vehicle';
    id: string;
    organization?: Organization;
    organizationId?: string;
    property?: Property;
    propertyId?: string;
    location?: Location;
    locationId?: string;
    licensePlate?: string;
    make?: string;
    model?: string;
    year?: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface VehicleFormValues
    extends Omit<
        Vehicle,
        | '__typename'
        | 'id'
        | 'organization'
        | 'property'
        | 'updatedAt'
        | 'createdAt'
        | 'location'
    > {}

// View ./Organization.ts for explanation on this
const vehicleFormKeys = [
    'licensePlate',
    'organizationId',
    'propertyId',
    'locationId',
    'make',
    'model',
    'year',
] as const;

// the following line will error if vehicleFormKeys has entries not in keyof VehicleFormValues:
type ExtraVehicleFormKeysWarning<
    T extends never = Exclude<
        (typeof vehicleFormKeys)[number],
        keyof VehicleFormValues
    >
> = void;
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Type 'UNION_OF_EXTRA_KEY_NAMES_HERE' does not satisfy the constraint 'never'

// the following line will error if vehicleFormKeys is missing entries from keyof VehicleFormValues:
type MissingVehicleFormKeysWarning<
    T extends never = Exclude<
        keyof VehicleFormValues,
        (typeof vehicleFormKeys)[number]
    >
> = void;
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Type 'UNION_OF_MISSING_KEY_NAMES_HERE' does not satisfy the constraint 'never'

export const convertIntoFormValues = (
    vehicle: Vehicle | VehicleFormValues
): VehicleFormValues => {
    return pick(vehicle, ...vehicleFormKeys);
};

export type GetVehicleResponse = {
    vehicles: Array<Vehicle>;
};

export const extractVehicles = (data: GetVehicleResponse) => {
    return data && data.vehicles;
};
