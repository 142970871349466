import { useMutation } from '@apollo/client';
import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
} from '@coreui/react';
import { CLoadingButton } from '@coreui/react-pro';
import { loader } from 'graphql.macro';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../../hooks';
import Cluster, { convertIntoFormValues } from '../../../models/Cluster';
import store from '../../../store';
import { addToast } from '../../Toaster/slice';
import {
    selectInitialCluster,
    selectIsOpen,
    selectLocationId,
    selectPanelId,
    selectProperty,
    selectPropertyId,
    setOpen,
} from './slice';
import { locationLabel } from '../../../utils/labels';

const createClusterMutation = loader('./createCluster.graphql');
const updateClusterMutation = loader('./updateCluster.graphql');

export default function EditClusterModal() {
    const isOpen = useAppSelector(selectIsOpen);
    const propertyId = useAppSelector(selectPropertyId);
    const locationId = useAppSelector(selectLocationId);
    const panelId = useAppSelector(selectPanelId);
    const property = useAppSelector(selectProperty);
    const initialCluster = useAppSelector(selectInitialCluster);
    const modalTitle = initialCluster ? 'Edit Cluster' : 'Add Cluster';

    const { handleSubmit, register, reset, watch, resetField } =
        useForm<Cluster>({
            defaultValues: {
                propertyId,
                locationId: locationId || '',
                panelId: panelId || '',
                ...initialCluster,
            },
        });

    const watchLocationId = watch('locationId');
    const panels = watchLocationId
        ? property?.locations.find((l) => l.id === watchLocationId)?.panels
        : property?.panels;

    useEffect(() => {
        if (initialCluster)
            reset({
                propertyId,
                locationId: locationId || '',
                panelId: panelId || '',
                ...initialCluster,
            });
        else
            reset({
                propertyId,
                locationId: locationId || '',
                panelId: panelId || '',
            });
    }, [initialCluster, locationId, propertyId, panelId, reset]);

    const [mutate, { data, error, loading }] = useMutation(
        initialCluster ? updateClusterMutation : createClusterMutation
    );

    useEffect(() => {
        if (error) {
            store.dispatch(
                addToast({
                    title: `${modalTitle} Error`,
                    message: error.message,
                    color: 'red',
                })
            );
        }
        if (data) {
            store.dispatch(
                addToast({
                    title: `${modalTitle} Success`,
                    message: 'Success',
                    color: 'green',
                })
            );
            store.dispatch(setOpen(false));
        }
    }, [error, data, modalTitle]);

    const onSubmit = async (formValues: Cluster) =>
        mutate({
            variables: {
                id: initialCluster?.id,
                cluster: convertIntoFormValues(formValues),
            },
        });

    return (
        <CModal
            size="lg"
            visible={isOpen}
            alignment="center"
            onClose={() => store.dispatch(setOpen(false))}
        >
            <CForm onSubmit={handleSubmit(onSubmit)}>
                <CModalHeader>
                    <CModalTitle>{modalTitle}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow lg={{ gutterY: 2 }}>
                        <CCol md={6}>
                            <CFormLabel htmlFor="cluster-name">Name</CFormLabel>
                            <CFormInput
                                id="cluster-name"
                                placeholder="Name"
                                {...register('name', {
                                    required: true,
                                })}
                            />
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel htmlFor="cluster-location">
                                {locationLabel}
                            </CFormLabel>
                            <CFormSelect
                                id="cluster-location"
                                disabled={!!locationId}
                                {...register('locationId', {
                                    setValueAs: (value) =>
                                        value === 'No Location' ? null : value,
                                    onChange: () => resetField('panelId'),
                                })}
                            >
                                <option value="">No {locationLabel}</option>
                                {(property?.locations || []).map((l) => (
                                    <option value={l.id}>{l.name}</option>
                                ))}
                            </CFormSelect>
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel htmlFor="cluster-parent">
                                Panel (Optional)
                            </CFormLabel>
                            <CFormSelect
                                id="cluster-parent"
                                {...register('panelId', {
                                    setValueAs: (value) =>
                                        value === 'No Panel' ? null : value,
                                })}
                                disabled={!!panelId}
                            >
                                <option value="">No Panel</option>
                                {(panels || []).map((p) => (
                                    <option value={p.id}>{p.name}</option>
                                ))}
                            </CFormSelect>
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel htmlFor="cluster-maxAmps">
                                Max Amps
                            </CFormLabel>
                            <CFormInput
                                placeholder="32"
                                type="number"
                                required
                                {...register('maxAmps', {
                                    valueAsNumber: true,
                                })}
                            />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="secondary"
                        onClick={() => store.dispatch(setOpen(false))}
                        disabled={loading}
                    >
                        Close
                    </CButton>
                    <CLoadingButton
                        color="primary"
                        type="submit"
                        loading={loading}
                    >
                        Save changes
                    </CLoadingButton>
                </CModalFooter>
            </CForm>
        </CModal>
    );
}
