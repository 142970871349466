import jwtDecode from 'jwt-decode';
import { JwtUser } from '../models/JwtUser';

export enum Role {
    Admin,
    AdminReadOnly,
    Support,
    Organization,
    OrganizationReadOnly,
    Valet,
    Property,
    PropertyReadOnly,
    User,
}

export type RoleStrings = keyof typeof Role;

// if we don't receive a jwt, then fetch it from local storage
const decodeChargieJwt = (token?: string) => {
    token ||= localStorage.getItem('user-token') ?? undefined;
    if (!token) {
        return null;
    }
    try {
        return jwtDecode<JwtUser>(token);
    } catch (err) {
        return null;
    }
};

export const hasRole = (key: RoleStrings, token?: string): boolean => {
    const currentRoleString: RoleStrings | null = userRole(token);
    if (!currentRoleString) return false;
    return Role[key] >= Role[currentRoleString];
};

export const userRole = (token?: string): RoleStrings | null => {
    const decoded = decodeChargieJwt(token);
    if (!decoded) return null;
    return decoded.role as RoleStrings;
};
