import { DateTime } from 'luxon';

export function startOfDay(d: DateTime) {
    return d.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
}

export function endOfDay(d: DateTime) {
    return d.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
}

export function displayDate(d: Date) {
    return d.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    });
}

export function toPST(d: DateTime) {
    return d.setZone('America/Los_Angeles');
}

export function duration(
    start: DateTime,
    end: DateTime = DateTime.local({ zone: 'America/Los_Angeles' })
) {
    return end
        .diff(start, ['hours', 'minutes', 'seconds'])
        .toHuman({ unitDisplay: 'short' });
}

export function stringToDate(d: Date | string | undefined) {
    if (!d) return;
    if (typeof d === 'object') return d;

    const dt = DateTime.fromISO(d);
    return dt.toJSDate();
}

export function roundDecimals(num: number, decimals: number) {
    return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

// Returns object with only the properties defined as Array<K>
// Array<K> is usually the list of properties for a type derived from T
export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
    return keys.reduce((o, k) => ((o[k] = obj[k]), o), {} as Pick<T, K>);
}

export function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
}

type ConditionalWrapProps = {
    children: React.ReactNode;
    condition: boolean;
    wrap: Function;
};

export const ConditionalWrap = ({
    condition,
    wrap,
    children,
}: ConditionalWrapProps) => (condition ? wrap(children) : children);

export const uniqueArrayByProperty = function <T, K extends keyof T>(
    arr: T[],
    key: K
): T[] {
    const map = new Map<T[K], T>();
    arr.filter((item) => !!item).forEach((item) => {
        map.set(item[key], item);
    });
    return Array.from(map.values());
};

export function throttle(fn: Function, delay: number) {
    let lastCall = 0;
    return function (...args: any) {
        const now = new Date().getTime();
        if (now - lastCall >= delay) {
            lastCall = now;
            return fn(...args);
        }
    };
}
