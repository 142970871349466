import { DropDownOption } from '../models/DropDownOption';
import { RoleStrings } from './auth';

export const carrierNames: Array<DropDownOption> = [
    { value: 'ATT', name: 'AT&T' },
    { value: 'VERIZON', name: 'Verizon' },
    { value: 'TMOBILE', name: 'TMobile' },
    { value: 'OTHER', name: 'Other' },
    { value: 'MIXED', name: 'Mixed' },
];

export const utilityProviders: Array<DropDownOption> = [
    { value: 'LADWP', name: 'LADWP' },
    { value: 'SCE', name: 'SCE' },
    { value: 'NVENERGY', name: 'NVENERGY' },
    { value: 'TEP', name: 'TEP' },
    { value: 'SDGE', name: 'SDGE' },
    { value: 'MID', name: 'MID' },
    { value: 'SRP', name: 'SRP' },
    { value: 'SMUD', name: 'SMUD' },
    { value: 'RMP', name: 'RMP' },
    { value: 'APU', name: 'APU' },
    { value: 'SVCE', name: 'SVCE' },
    { value: 'PGE', name: 'PG&E' },
    { value: 'BWP', name: 'BW&P' },
    { value: 'PWP', name: 'PW&P' },
    { value: 'GWP', name: 'GWP' },
    { value: 'TEST', name: 'TEST' },
    { value: 'DUKEENERGY', name: 'Duke Energy' },
    { value: 'DOMINIONVIRGINIA', name: 'Dominion Virginia' },
    { value: 'AUSTINENERGY', name: 'Austin Energy' },
    { value: 'GEORGIAPOWER', name: 'Georgia Power' },
    { value: 'ROSEVILLEELECTRIC', name: 'Roseville Electric' },
].sort((a, b) => a.value.localeCompare(b.value));

export const gatewayTypes: Array<DropDownOption> = [
    { value: 'EVOCHARGE', name: 'Evocharge' },
    { value: 'CRADLEPOINT', name: 'Cradlepoint' },
    { value: 'MIKROTIK', name: 'Mikrotik' },
    { value: 'SIERRA', name: 'Sierra' },
    { value: 'MIXED', name: 'Mixed' },
];

export const touEnabledUtilityList = [
    'SCE',
    'SRP',
    'SDGE',
    'SMUD',
    'PGE',
    'LADWP',
    'TEST',
];

export const productionStatuses: Array<DropDownOption> = [
    { value: 'PREPRODUCTION', name: 'PREPRODUCTION' },
    { value: 'INCUBATION', name: 'INCUBATION' },
    { value: 'TICKETED', name: 'TICKETED' },
    { value: 'MAINTENANCE', name: 'MAINTENANCE' },
    { value: 'PRODUCTION', name: 'PRODUCTION' },
    { value: 'DEGRADED', name: 'DEGRADED' },
    { value: 'TEST', name: 'TEST' },
];

export const stationTypes: Array<DropDownOption> = [
    { value: 'NONE', name: 'None' },
    { value: 'PRIVATE', name: 'PRIVATE' },
    { value: 'PUBLIC', name: 'PUBLIC' },
];

export const fundingTypeValues: Array<DropDownOption> = [
    { value: 'FREE', name: 'Free' },
    { value: 'FOURPACK', name: '4 Pack' },
    { value: 'CASH', name: 'Cash' },
    { value: 'GSA', name: 'GSA' },
    { value: 'VW', name: 'VW Mitigation Trust Grant' },
    { value: 'LADWP', name: 'LADWP Rebate' },
    { value: 'SCE_CSMR', name: 'SCE CSMR Rebate' },
    { value: 'SCE_MUDDAC', name: 'SCE MUDDAC Rebate' },
    { value: 'SCE_TURNKEY', name: 'SCE Turnkey Rebate' },
    { value: 'SCE_NC', name: 'SCE NC Rebate' },
    { value: 'NVE', name: 'NVE Rebate' },
    { value: 'CALeVIP', name: 'CALeVIP Rebate' },
    { value: 'TEP', name: 'TEP Rebate' },
    { value: 'SDGE', name: 'SDGE Rebate' },
    { value: 'SJV', name: 'SJV Rebate' },
    { value: 'SRP', name: 'SRP Rebate' },
    { value: 'SMUD', name: 'SMUD Rebate' },
    { value: 'RMP', name: 'RMP Rebate' },
    { value: 'APU', name: 'APU Rebate' },
    { value: 'PCE', name: 'PCE Rebate' },
    { value: 'SVCE', name: 'SVCE Rebate' },
    { value: 'JUNY', name: 'JUNY Rebate' },
];

export const stationLevels = ['None', 2, 3];

export const stationConnectors = [1, 2];

export const SCE_CREDIT_PROGRAM_ID = '288f7241-b37e-44e4-800c-e2ffd5e8d189';

export const NUMERIC_KEYS_ALLOWED: string[] = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'Backspace',
    'Delete',
];

export const DEFAULT_DECIMAL_PLACES = 0;

export const GUEST_ACCESS_PER_ORG = 5;

const SPLUNK_BASE = 'https://splunk.chargieengine.com:8000/en-US/app/search';

export const SPLUNK_CLUSTER_URL = `${SPLUNK_BASE}/station_investigation?form.field1.earliest=-24h%40h&form.field1.latest=now&form.clusterid=`;

export const SPLUNK_SERIAL_URL = `${SPLUNK_BASE}/stations_investigation?form.field1.earliest=-24h%40h&form.field1.latest=now&form.serial=`;

export const SPLUNK_USER_URL = `${SPLUNK_BASE}/user_investigation?form.field1.earliest=-24h%40h&form.field1.latest=now&form.userId=`;

export const ALLOWED_LOGIN_ROLES: Array<RoleStrings> = ['Admin', 'Support'];

export const ALLOWED_FEES_ROLES: Array<RoleStrings> = ['Admin', 'Support'];
