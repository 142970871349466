import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
} from '@coreui/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Vehicle, {
    VehicleFormValues,
    convertIntoFormValues,
} from '../../../models/Vehicle';
import { useAppSelector } from '../../../hooks';
import {
    selectInitialVehicle,
    selectIsOpen,
    selectNetworkOrganizations,
    setOpen,
} from './slice';
import store from '../../../store';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { addToast } from '../../Toaster/slice';
import {
    locationLabel,
    organizationLabel,
    propertyLabel,
} from '../../../utils/labels';

const addVehicleMutation = loader('./addVehicle.graphql');
const updateVehicleMutation = loader('./updateVehicle.graphql');

export default function EditVehicleModal() {
    const readOnly = false; // TODOIVAN check permissions

    const isOpen = useAppSelector(selectIsOpen);
    const networkOrganizations = useAppSelector(selectNetworkOrganizations);
    const initialVehicle = useAppSelector(selectInitialVehicle);

    const modalTitle = initialVehicle ? 'Edit Vehicle' : 'Add Vehicle';

    const { handleSubmit, register, watch, reset } = useForm<VehicleFormValues>(
        {
            defaultValues: {
                licensePlate: '',
                ...initialVehicle,
            },
        }
    );

    useEffect(() => {
        if (initialVehicle) reset(initialVehicle);
        else reset({ licensePlate: '' });
    }, [initialVehicle, reset]);

    const [mutate, { data, error, loading, reset: resetMutation }] =
        useMutation(
            initialVehicle ? updateVehicleMutation : addVehicleMutation,
            {
                update: (cache, { data: { addVehicle, updateVehicle } }) => {
                    // keep the Apollo cache up to date without having to fetch the data again
                    cache.modify({
                        fields: {
                            vehicles(cachedVehicles = [], { readField }) {
                                // updating or adding
                                if (!initialVehicle)
                                    return [addVehicle, ...cachedVehicles];
                                const otherVehicles = cachedVehicles.filter(
                                    (vehicle: Vehicle) =>
                                        initialVehicle.id !==
                                        readField('id', { ...vehicle })
                                );
                                return [updateVehicle, ...otherVehicles];
                            },
                        },
                    });
                },
            }
        );

    const watchOrganization = watch('organizationId');
    const availableProps = !!watchOrganization
        ? networkOrganizations.find((x) => x.id === watchOrganization)
              ?.properties
        : networkOrganizations.flatMap((x) => x.properties || []);

    useEffect(() => {
        if (error) {
            store.dispatch(
                addToast({
                    title: `${modalTitle} Error`,
                    message: error.message,
                    color: 'red',
                })
            );
        }
        if (data) {
            store.dispatch(
                addToast({
                    title: `${modalTitle} Success`,
                    message: 'Success',
                    color: 'green',
                })
            );
            store.dispatch(setOpen(false));
            resetMutation();
        }
    }, [error, data, modalTitle, resetMutation]);

    const onSubmit = async (formValues: VehicleFormValues) =>
        mutate({
            variables: {
                id: initialVehicle?.id,
                input: convertIntoFormValues(formValues),
            },
        });

    return (
        <CModal
            size="lg"
            visible={isOpen}
            alignment="center"
            onClose={() => store.dispatch(setOpen(false))}
        >
            <CForm onSubmit={handleSubmit(onSubmit)}>
                <CModalHeader>
                    <CModalTitle>{modalTitle}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow lg={{ gutterY: 3 }}>
                        <CCol md={12}>
                            <CCol md={6}>
                                <CFormLabel htmlFor="licensePlate">
                                    License Plate
                                </CFormLabel>
                                <CFormInput
                                    placeholder="License Plate"
                                    readOnly={readOnly}
                                    plainText={readOnly}
                                    {...register('licensePlate', {
                                        required: true,
                                    })}
                                />
                            </CCol>
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Make"
                                className="col-sm-2 col-form-label"
                            >
                                Make
                            </CFormLabel>
                            <CFormInput
                                placeholder="Make"
                                readOnly={readOnly}
                                plainText={readOnly}
                                {...register('make')}
                            />
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Model"
                                className="col-sm-2 col-form-label"
                            >
                                Model
                            </CFormLabel>
                            <CFormInput
                                placeholder="Model"
                                readOnly={readOnly}
                                plainText={readOnly}
                                {...register('model')}
                            />
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Year"
                                className="col-sm-2 col-form-label"
                            >
                                Year
                            </CFormLabel>
                            <CFormInput
                                placeholder="Year"
                                readOnly={readOnly}
                                plainText={readOnly}
                                {...register('year')}
                            />
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Organization"
                                className="col-sm-3 col-form-label"
                            >
                                {organizationLabel}
                            </CFormLabel>
                            <CFormSelect
                                placeholder="Organization"
                                {...register('organizationId')}
                            >
                                <option key="empty" value="">
                                    Select an option...
                                </option>
                                {networkOrganizations.map((organization) => (
                                    <option
                                        key={organization.id}
                                        value={organization.id}
                                    >
                                        {organization.name}
                                    </option>
                                ))}
                            </CFormSelect>
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Property"
                                className="col-sm-3 col-form-label"
                            >
                                {propertyLabel}
                            </CFormLabel>
                            <CFormSelect
                                placeholder="Property"
                                {...register('propertyId')}
                            >
                                <option key="empty" value="">
                                    Select an option...
                                </option>
                                {(availableProps || []).map((property) => (
                                    <option
                                        key={property.id}
                                        value={property.id}
                                    >
                                        {property.name}
                                    </option>
                                ))}
                            </CFormSelect>
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Location"
                                className="col-sm-2 col-form-label"
                            >
                                {locationLabel}
                            </CFormLabel>
                            <CFormInput
                                placeholder={locationLabel}
                                readOnly={readOnly}
                                plainText={readOnly}
                                {...register('locationId')}
                            />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="secondary"
                        onClick={() => store.dispatch(setOpen(false))}
                        disabled={loading}
                    >
                        Close
                    </CButton>
                    <CButton color="primary" type="submit" disabled={loading}>
                        Save changes
                    </CButton>
                </CModalFooter>
            </CForm>
        </CModal>
    );
}
