import { useMutation, useQuery } from '@apollo/client';
import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
} from '@coreui/react';
import { CLoadingButton, CMultiSelect } from '@coreui/react-pro';
import { loader } from 'graphql.macro';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../../hooks';
import { extractlistRoles } from '../../../models/RoleRecord';
import { UserFormValues, convertIntoFormValues } from '../../../models/User';
import store from '../../../store';
import { hasRole } from '../../../utils/auth';
import { organizationLabel, propertyLabel } from '../../../utils/labels';
import { addToast } from '../../Toaster/slice';
import { selectNetworkOrganizations } from '../EditVehicleModal/slice';
import { selectInitialUser, selectIsOpen, setOpen } from './slice';

const rolesQuery = loader('./listRoles.graphql');
const createUserMutation = loader('./createUser.graphql');
const updateUserMutation = loader('./updateUser.graphql');

export default function EditUserModal() {
    const isOpen = useAppSelector(selectIsOpen);
    const initialUser = useAppSelector(selectInitialUser);
    const organizations = useAppSelector(selectNetworkOrganizations);
    const isAdmin = hasRole('Admin');
    const [multiInvalid, setMultiInvalid] = useState(false);

    const modalTitle = initialUser ? 'Edit User' : 'Add User';

    const { data: rolesData } = useQuery(rolesQuery);
    const roles = extractlistRoles(rolesData);

    const { handleSubmit, register, reset, watch, getValues, setValue } =
        useForm<UserFormValues>({
            defaultValues: {
                ...initialUser,
                roleId: initialUser?.role?.id,
                organizationIds:
                    initialUser?.organizations?.map(
                        (organization) => organization.id
                    ) || [],
                propertyIds:
                    initialUser?.properties?.map((property) => property.id) ||
                    [],
            },
        });

    const watchRole = watch('roleId');
    const watchProperties = watch('propertyIds');
    const watchOrganizations = watch('propertyIds');

    useEffect(() => {
        const selectedRole = roles.find((role) => role.id === watchRole)?.name;
        switch (selectedRole) {
            case 'Organization':
            case 'OrganizationReadOnly':
                setValue('propertyIds', []);
                setValue('propertyId', null);
                setMultiInvalid(true);
                break;
            case 'Property':
            case 'PropertyReadOnly':
            case 'Valet':
                setValue('organizationIds', []);
                setValue('organizationId', null);
                setMultiInvalid(true);
                break;
            case '':
            case null:
            case undefined:
                break;
            default:
                setValue('propertyIds', []);
                setValue('propertyId', null);
                setValue('organizationIds', []);
                setValue('organizationId', null);
                setMultiInvalid(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchRole]);

    useEffect(() => {
        const selectedRole = roles.find((role) => role.id === watchRole)?.name;
        setMultiInvalid(
            ((selectedRole === 'Organization' ||
                selectedRole === 'OrganizationReadOnly') &&
                watchOrganizations?.length === 0) ||
                ((selectedRole === 'Property' ||
                    selectedRole === 'PropertyReadOnly' ||
                    selectedRole === 'Valet') &&
                    watchProperties?.length === 0)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchProperties, watchOrganizations]);

    useEffect(() => {
        if (initialUser) {
            reset({
                ...initialUser,
                roleId: initialUser?.role?.id,
            });

            // Set the organizations and properties separately
            // Otherwise they will be cleared when the role is initially set
            setTimeout(() => {
                setValue(
                    'organizationIds',
                    initialUser?.organizations?.map(
                        (organization) => organization.id
                    ) || []
                );
                setValue(
                    'propertyIds',
                    initialUser?.properties?.map((property) => property.id) ||
                        []
                );
            }, 100);
        } else reset({});
    }, [initialUser, reset, setValue]);

    const [mutate, { data, error, loading }] = useMutation(
        initialUser ? updateUserMutation : createUserMutation
    );

    useEffect(() => {
        if (error) {
            store.dispatch(
                addToast({
                    title: `${modalTitle} Error`,
                    message: error.message,
                    color: 'red',
                })
            );
        }
        if (data) {
            store.dispatch(
                addToast({
                    title: `${modalTitle} Success`,
                    message: 'Success',
                    color: 'green',
                })
            );
            store.dispatch(setOpen(false));
        }
    }, [error, data, modalTitle]);

    const onSubmit = async (user: UserFormValues) => {
        const formValues = convertIntoFormValues(user);
        if (initialUser?.id) {
            delete formValues.email;
        }
        return mutate({
            variables: {
                id: initialUser?.id,
                user: formValues,
            },
        });
    };

    return (
        <CModal
            size="lg"
            visible={isOpen}
            alignment="center"
            onClose={() => store.dispatch(setOpen(false))}
        >
            <CForm onSubmit={handleSubmit(onSubmit)}>
                <CModalHeader>
                    <CModalTitle>{modalTitle}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow lg={{ gutterY: 2 }}>
                        <CCol md={6}>
                            <CFormLabel htmlFor="user-name-first">
                                First Name
                            </CFormLabel>
                            <CFormInput
                                id="user-name-first"
                                placeholder="First Name"
                                {...register('nameFirst')}
                            />
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel htmlFor="user-name-last">
                                Last Name
                            </CFormLabel>
                            <CFormInput
                                id="user-name-last"
                                placeholder="Last Name"
                                {...register('nameLast')}
                            />
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel htmlFor="user-email">Email</CFormLabel>
                            <CFormInput
                                id="user-email"
                                placeholder="Email"
                                {...register('email', { required: true })}
                                disabled={!!initialUser}
                            />
                        </CCol>
                        {isAdmin && (
                            <>
                                <CCol md={6}>
                                    <CFormLabel htmlFor="user-email">
                                        Role
                                    </CFormLabel>
                                    <CFormSelect
                                        {...register('roleId', {
                                            required: true,
                                        })}
                                    >
                                        <option key="empty" value="">
                                            Select an option...
                                        </option>
                                        {roles.map((role) => (
                                            <option
                                                key={role.id}
                                                value={role.id}
                                            >
                                                {role.name}
                                            </option>
                                        ))}
                                    </CFormSelect>
                                </CCol>
                                {!!roles.find(
                                    (r) =>
                                        r.id === watchRole &&
                                        (r.name === 'Organization' ||
                                            r.name === 'OrganizationReadOnly')
                                ) && (
                                    <CCol md={6}>
                                        <CFormLabel htmlFor="user-organization">
                                            {organizationLabel}
                                        </CFormLabel>
                                        <CMultiSelect
                                            invalid={multiInvalid}
                                            required
                                            selectionType="counter"
                                            virtualScroller
                                            onChange={(selected) =>
                                                setValue(
                                                    'organizationIds',
                                                    selected.map(
                                                        (s) => s.value as string
                                                    )
                                                )
                                            }
                                            optionsMaxHeight={1}
                                            options={organizations.map(
                                                (organization) => ({
                                                    text: organization.name,
                                                    value: organization.id,
                                                    selected: !!getValues(
                                                        'organizationIds'
                                                    )?.find(
                                                        (o) =>
                                                            o ===
                                                            organization.id
                                                    ),
                                                })
                                            )}
                                        />
                                    </CCol>
                                )}
                                {!!roles.find(
                                    (r) =>
                                        r.id === watchRole &&
                                        (r.name === 'Property' ||
                                            r.name === 'PropertyReadOnly' ||
                                            r.name === 'Valet')
                                ) && (
                                    <CCol md={6}>
                                        <CFormLabel htmlFor="user-property">
                                            {propertyLabel}
                                        </CFormLabel>
                                        <CMultiSelect
                                            invalid={multiInvalid}
                                            required
                                            selectionType="counter"
                                            virtualScroller
                                            onChange={(selected) =>
                                                setValue(
                                                    'propertyIds',
                                                    selected.map(
                                                        (s) => s.value as string
                                                    )
                                                )
                                            }
                                            options={organizations
                                                .filter(
                                                    (organization) =>
                                                        organization.properties
                                                            .length > 0
                                                )
                                                .map((organization) => ({
                                                    label: organization.name,
                                                    options:
                                                        organization.properties.map(
                                                            (prop) => ({
                                                                text: prop.name,
                                                                value: prop.id,
                                                                selected:
                                                                    !!getValues(
                                                                        'propertyIds'
                                                                    )?.find(
                                                                        (p) =>
                                                                            p ===
                                                                            prop.id
                                                                    ),
                                                            })
                                                        ),
                                                }))}
                                        />
                                    </CCol>
                                )}
                            </>
                        )}
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="secondary"
                        onClick={() => store.dispatch(setOpen(false))}
                        disabled={loading}
                    >
                        Close
                    </CButton>
                    <CLoadingButton
                        color="primary"
                        type="submit"
                        loading={loading}
                    >
                        Save changes
                    </CLoadingButton>
                </CModalFooter>
            </CForm>
        </CModal>
    );
}
