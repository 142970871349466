import { pick } from '../utils/helper';
import Organization from './Organization';
import Property from './Property';
import UserRole from './UserRole';

export default interface User {
    __typename: 'User';
    id: string;
    password: string;
    organization: Organization;
    organizationId?: string | null;
    property: Property;
    propertyId?: string | null;
    email?: string;
    phone?: string;
    nameFirst?: string;
    nameLast?: string;
    stripeCustomerId?: string;
    createdAt?: Date;
    isOperator: boolean;
    reloadThreshold?: number;
    reloadAmount?: number;
    reloadAmountNegative?: number;
    isDeleted?: boolean;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    isPhoneConfirmed?: boolean;
    role?: UserRole;
    reload: boolean;
    isEmailConfirmed: boolean;
    organizationIds?: string[];
    propertyIds?: string[];
    organizations: Organization[];
    properties: Property[];
    roleId?: string;
}

export interface UserFormValues
    extends Pick<
        User,
        | 'email'
        | 'phone'
        | 'organizationId'
        | 'propertyId'
        | 'nameFirst'
        | 'nameLast'
        | 'organizationIds'
        | 'propertyIds'
        | 'roleId'
    > {}

// View ./Organization.ts for explanation on this
const userFormKeys = [
    'email',
    'phone',
    'roleId',
    'organizationId',
    'propertyId',
    'nameFirst',
    'nameLast',
    'organizationIds',
    'propertyIds',
] as const;

// the following line will error if userFormKeys has entries not in keyof UserFormValues:
type ExtraUserFormKeysWarning<
    T extends never = Exclude<
        (typeof userFormKeys)[number],
        keyof UserFormValues
    >
> = void;

// the following line will error if userFormKeys is missing entries from keyof UserFormValues:
type MissingOrgFormKeysWarning<
    T extends never = Exclude<
        keyof UserFormValues,
        (typeof userFormKeys)[number]
    >
> = void;

export const convertIntoFormValues = (
    user: User | UserFormValues
): UserFormValues => {
    return pick(user, ...userFormKeys);
};

export type GetUsersResponse = {
    users: Array<User>;
};

export const extractUsers = (data: GetUsersResponse) => {
    return data && data.users;
};

type UpdteUserResponse = {
    updateUser: User;
};

export const extractUpdateUser = (data: UpdteUserResponse) => {
    return data?.updateUser;
};

type CreateUserResponse = {
    createUser: User;
};

export const extractCreateUser = (data: CreateUserResponse) => {
    return data?.createUser;
};
