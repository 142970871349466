// not to confuse with 'Role' as a GQL directive
export default interface RoleRecord {
    id: string;
    name: string;
    ordering?: number;
}

type listRolesResponse = {
    listRoles: Array<RoleRecord>;
}

export const extractlistRoles = (data: listRolesResponse) => {
    return data?.listRoles ?? [];
}
