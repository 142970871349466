import { useMutation } from '@apollo/client';
import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
} from '@coreui/react';
import { CLoadingButton } from '@coreui/react-pro';
import { loader } from 'graphql.macro';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../../hooks';
import Location, { convertIntoFormValues } from '../../../models/Location';
import store from '../../../store';
import { carrierNames, gatewayTypes } from '../../../utils/constants';
import { addToast } from '../../Toaster/slice';
import {
    selectInitialLocation,
    selectIsOpen,
    selectPropertyId,
    setOpen,
} from './slice';
import { locationLabel } from '../../../utils/labels';

const createLocationMutation = loader('./createLocation.graphql');
const updateLocationMutation = loader('./updateLocation.graphql');

export default function EditLocationModal() {
    const isOpen = useAppSelector(selectIsOpen);
    const propertyId = useAppSelector(selectPropertyId);
    const initialLocation = useAppSelector(selectInitialLocation);
    const modalTitle = initialLocation
        ? `Edit ${locationLabel}`
        : `Add ${locationLabel}`;

    const { handleSubmit, register, reset } = useForm<Location>({
        defaultValues: { propertyId, ...initialLocation },
    });

    useEffect(() => {
        if (initialLocation) reset(initialLocation);
        else if (propertyId) reset({ propertyId });
    }, [initialLocation, propertyId, reset]);

    const [mutate, { data, error, loading }] = useMutation(
        initialLocation ? updateLocationMutation : createLocationMutation
    );

    useEffect(() => {
        if (error) {
            store.dispatch(
                addToast({
                    title: `${modalTitle} Error`,
                    message: error.message,
                    color: 'red',
                })
            );
        }
        if (data) {
            store.dispatch(
                addToast({
                    title: `${modalTitle} Success`,
                    message: 'Success',
                    color: 'green',
                })
            );
            store.dispatch(setOpen(false));
        }
    }, [error, data, modalTitle]);

    const onSubmit = async (formValues: Location) =>
        mutate({
            variables: {
                id: initialLocation?.id,
                location: convertIntoFormValues(formValues),
            },
        });

    return (
        <CModal
            size="lg"
            visible={isOpen}
            alignment="center"
            onClose={() => store.dispatch(setOpen(false))}
        >
            <CForm onSubmit={handleSubmit(onSubmit)}>
                <CModalHeader>
                    <CModalTitle>{modalTitle}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow lg={{ gutterY: 2 }}>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Name"
                                className="col-sm-3 col-form-label"
                            >
                                Name
                            </CFormLabel>
                            <CFormInput
                                placeholder="Name"
                                {...register('name', {
                                    required: true,
                                })}
                            />
                        </CCol>
                        <CCol md={3}>
                            <CFormLabel
                                htmlFor="GatewayType"
                                className="col-sm-3 col-form-label"
                            >
                                Gateway
                            </CFormLabel>
                            <CFormSelect {...register('gatewayType')}>
                                <option value={''}>Choose...</option>
                                {gatewayTypes.map((gtwy) => (
                                    <option key={gtwy.value} value={gtwy.value}>
                                        {gtwy.name}
                                    </option>
                                ))}
                            </CFormSelect>
                        </CCol>
                        <CCol md={3}>
                            <CFormLabel
                                htmlFor="CarrierName"
                                className="col-sm-3 col-form-label"
                            >
                                Carrier
                            </CFormLabel>
                            <CFormSelect {...register('carrierName')}>
                                <option value={''}>Choose...</option>
                                {carrierNames.map((car) => (
                                    <option key={car.value} value={car.value}>
                                        {car.name}
                                    </option>
                                ))}
                            </CFormSelect>
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Latitude"
                                className="col-md-4 col-form-label"
                            >
                                Latitude
                            </CFormLabel>
                            <CFormInput
                                placeholder="Latitude"
                                {...register('latitude')}
                            />
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Longitude"
                                className="col-md-4 col-form-label"
                            >
                                Longitude
                            </CFormLabel>
                            <CFormInput
                                placeholder="Longitude"
                                {...register('longitude')}
                            />
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Managed Account ID"
                                className="col-lg-12 col-form-label"
                            >
                                Managed Account ID
                            </CFormLabel>
                            <CFormInput
                                placeholder="Managed Account ID"
                                {...register('managedAccountId')}
                            />
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Note"
                                className="col-md-4 col-form-label"
                            >
                                Note
                            </CFormLabel>
                            <CFormTextarea
                                placeholder="Note"
                                {...register('note')}
                            />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="secondary"
                        onClick={() => store.dispatch(setOpen(false))}
                        disabled={loading}
                    >
                        Close
                    </CButton>
                    <CLoadingButton
                        color="primary"
                        type="submit"
                        loading={loading}
                    >
                        Save changes
                    </CLoadingButton>
                </CModalFooter>
            </CForm>
        </CModal>
    );
}
