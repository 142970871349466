import { pick } from '../utils/helper';
import Cluster from './Cluster';
import Panel from './Panel';
import Property from './Property';

export default interface Location {
    id: string;
    name: string;
    longitude?: number;
    latitude?: number;
    gatewayType?: string;
    carrierName?: string;
    propertyId: string;
    panels: Panel[];
    clusters: Cluster[];
    property: Property;
    managedAccountId: string;
    accountId: string;
    note: string;
}

export interface LocationFormValues
    extends Omit<
        Location,
        'id' | 'panels' | 'clusters' | 'property' | '__typename'
    > {}

const formKeys = [
    'name',
    'longitude',
    'latitude',
    'gatewayType',
    'carrierName',
    'propertyId',
    'managedAccountId',
    'accountId',
    'note',
] as const;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ExtraFormKeysWarning<
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    T extends never = Exclude<
        (typeof formKeys)[number],
        keyof LocationFormValues
    >
> = void;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type MissingFormKeysWarning<
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    T extends never = Exclude<
        keyof LocationFormValues,
        (typeof formKeys)[number]
    >
> = void;

export const convertIntoFormValues = (
    location: Location | LocationFormValues
): LocationFormValues => {
    const formValues = pick(location, ...formKeys);
    return Object.keys(formValues).reduce((acc: any, key) => {
        acc[key] =
            formValues[key as keyof LocationFormValues] === ''
                ? null
                : formValues[key as keyof LocationFormValues];
        if ((key === 'latitude' || key === 'longitude') && acc[key] != null) {
            acc[key] = acc[key].toString();
        }
        return acc;
    }, {});
};
