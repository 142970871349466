import { pick } from '../utils/helper';
import Station from './Station';

export default interface Cluster {
    id: string;
    name: string;
    maxAmps?: number;
    propertyId?: string;
    panelId?: String | null;
    note?: string;
    stations: Station[];
    locationId?: string;
}

export interface ClusterFormValues
    extends Pick<
        Cluster,
        'name' | 'maxAmps' | 'propertyId' | 'panelId' | 'locationId'
    > {}

// View ./Organization.ts for explanation on this
const clusterFormKeys = [
    'name',
    'maxAmps',
    'propertyId',
    'panelId',
    'locationId',
] as const;

// the following line will error if clusterFormKeys has entries not in keyof ClusterFormValues:
type ExtraPropFormKeysWarning<
    T extends never = Exclude<
        (typeof clusterFormKeys)[number],
        keyof ClusterFormValues
    >
> = void;

// the following line will error if clusterFormKeys is missing entries from keyof ClusterFormValues:
type MissingOrgFormKeysWarning<
    T extends never = Exclude<
        keyof ClusterFormValues,
        (typeof clusterFormKeys)[number]
    >
> = void;

export const convertIntoFormValues = (
    cluster: Cluster | ClusterFormValues
): ClusterFormValues => {
    const formValues = pick(cluster, ...clusterFormKeys);
    return Object.keys(formValues).reduce((acc: any, key) => {
        let value = formValues[key as keyof ClusterFormValues];
        switch (key as keyof ClusterFormValues) {
            case 'panelId':
                if (value === '') value = null;
                break;
            case 'locationId':
                if (value === '') value = null;
                break;
        }
        acc[key] = value;

        return acc;
    }, {});
};

type createClusterResponse = {
    createCluster: Cluster;
};

export const extractCreateCluster = (data: createClusterResponse) => {
    return data?.createCluster;
};

type deleteteClusterResponse = {
    deleteCluster: Cluster;
};

export const extractDeleteCluster = (data: deleteteClusterResponse) => {
    return data?.deleteCluster;
};
