import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
} from '@coreui/react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
    locationLabel,
    organizationLabel,
    propertyLabel,
} from '../../../utils/labels';
import { calculateIndefinite } from '../../../utils/string';
import { useAppSelector } from '../../../hooks';
import {
    selectInitialRFID,
    selectIsOpen,
    selectNetworkOrganizations,
    selectUsers,
    selectVehicles,
    setOpen,
} from './slice';
import store from '../../../store';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { addToast } from '../../Toaster/slice';
import RFID, {
    RFIDFormValues,
    convertIntoFormValues,
} from '../../../models/RFID';
import { CMultiSelect } from '@coreui/react-pro';
import { Option } from '@coreui/react-pro/dist/components/multi-select/types';

const addRFIDMutation = loader('./linkOrgRFID.graphql');
const updateRFIDMutation = loader('./updateOrgRFID.graphql');

export default function EditRFIDModal() {
    const readOnly = false; // TODOIVAN check permissions

    const isOpen = useAppSelector(selectIsOpen);
    const networkOrganizations = useAppSelector(selectNetworkOrganizations);
    const initialRFID = useAppSelector(selectInitialRFID);
    const users = useAppSelector(selectUsers);
    const vehicles = useAppSelector(selectVehicles);
    const modalTitle = initialRFID ? 'Edit RFID' : 'Add RFID';

    const [filteredUsers, setFilteredUsers] = useState<Array<Option>>([]);

    const {
        handleSubmit,
        register,
        watch,
        reset,
        control,
        formState: { errors },
    } = useForm<RFIDFormValues>({
        defaultValues: {
            ...initialRFID,
        },
    });

    useEffect(() => {
        if (initialRFID) {
            reset(initialRFID);
            setFilteredUsers([
                {
                    value: initialRFID?.userId ?? '',
                    text: initialRFID?.user?.email ?? '',
                    selected: true,
                },
            ]);
        } else reset({ rawRfid: undefined });
    }, [initialRFID, reset]);

    const [mutate, { data, error, loading, reset: resetMutation }] =
        useMutation(initialRFID ? updateRFIDMutation : addRFIDMutation, {
            update: (cache, { data: { linkOrgRFID, updateOrgRFID } }) => {
                // keep the Apollo cache up to date without having to fetch the data again
                cache.modify({
                    fields: {
                        rfids(cachedRFIDs = [], { readField }) {
                            // updating or adding
                            if (!initialRFID)
                                return [linkOrgRFID, ...cachedRFIDs];
                            const otherRFIDs = cachedRFIDs.filter(
                                (rfid: RFID) =>
                                    initialRFID.id !==
                                    readField('id', { ...rfid })
                            );
                            return [updateOrgRFID, ...otherRFIDs];
                        },
                    },
                });
            },
        });

    const orgOptions = networkOrganizations.map((org) => ({
        value: org.id,
        text: org.name,
        selected: initialRFID?.organizationId === org.id,
    }));

    const watchOrganization = watch('organizationId');
    const propOptions = !!watchOrganization
        ? networkOrganizations
              .find((x) => x.id === watchOrganization)
              ?.properties.map((prop) => ({
                  value: prop.id,
                  text: prop.name,
                  selected: initialRFID?.propertyId === prop.id,
              })) ?? []
        : [];

    const vehicleOptions =
        vehicles?.map((v) => ({
            value: v.id,
            text: v.licensePlate ?? '',
            selected: initialRFID?.vehicleId === v.id,
        })) ?? [];

    useEffect(() => {
        if (error) {
            store.dispatch(
                addToast({
                    title: `${modalTitle} Error`,
                    message: error.message,
                    color: 'red',
                })
            );
        }
        if (data) {
            store.dispatch(
                addToast({
                    title: `${modalTitle} Success`,
                    message: 'Success',
                    color: 'green',
                })
            );
            store.dispatch(setOpen(false));
            resetMutation();
            reset();
        }
    }, [error, data, modalTitle, resetMutation, reset]);

    const onSubmit = async (formValues: RFIDFormValues) => {
        const converted = convertIntoFormValues(formValues);
        const { rawRfid, ...rest } = converted;
        // update expects 'rfid', while 'add' expects 'input'
        mutate({
            variables: {
                id: initialRFID?.id,
                input: converted,
                rfid: rest,
            },
        });
    };

    return (
        <CModal
            size="lg"
            visible={isOpen}
            alignment="center"
            onClose={() => store.dispatch(setOpen(false))}
        >
            <CForm onSubmit={handleSubmit(onSubmit)}>
                <CModalHeader>
                    <CModalTitle>{modalTitle}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow lg={{ gutterY: 3 }}>
                        <CCol md={12}>
                            <CCol md={6}>
                                <CFormLabel
                                    htmlFor="RFID"
                                    className="col-sm-2 col-form-label"
                                >
                                    RFID Number
                                </CFormLabel>
                                <CFormInput
                                    placeholder="RFID"
                                    readOnly={readOnly}
                                    plainText={readOnly}
                                    {...register('rawRfid', {
                                        required: true,
                                    })}
                                />
                            </CCol>
                        </CCol>
                        <CCol md={12}>
                            <p style={{ fontSize: '14px' }}>
                                Registering RFIDs to{' '}
                                {calculateIndefinite(organizationLabel)
                                    ? 'an'
                                    : 'a'}{' '}
                                {organizationLabel} restricts access to charging
                                stations within the entire {organizationLabel}.
                                To set further restrictions, assign to one or
                                more properties.
                            </p>
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Organization"
                                className="col-sm-2 col-form-label"
                            >
                                {organizationLabel}
                            </CFormLabel>
                            <Controller
                                name="organizationId"
                                control={control}
                                render={({ field: { onChange, ref } }) => (
                                    <CMultiSelect
                                        ref={ref}
                                        cleaner={true}
                                        options={orgOptions}
                                        multiple={false}
                                        onChange={(options) => {
                                            const option = options[0];
                                            if (!option) return;
                                            const value =
                                                typeof option.value === 'string'
                                                    ? option.value
                                                    : String(option.value);
                                            onChange(value);
                                        }}
                                        virtualScroller
                                    />
                                )}
                            />
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Property"
                                className="col-sm-3 col-form-label"
                            >
                                {propertyLabel}
                            </CFormLabel>
                            <Controller
                                name="propertyId"
                                control={control}
                                render={({ field: { onChange, ref } }) => (
                                    <CMultiSelect
                                        ref={ref}
                                        cleaner={true}
                                        options={propOptions}
                                        multiple={false}
                                        onChange={(options) => {
                                            const option = options[0];
                                            if (!option) return;
                                            const value =
                                                typeof option.value === 'string'
                                                    ? option.value
                                                    : String(option.value);
                                            onChange(value);
                                        }}
                                        virtualScroller
                                    />
                                )}
                            />
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Location"
                                className="col-sm-2 col-form-label"
                            >
                                {locationLabel}
                            </CFormLabel>
                            <CFormInput
                                placeholder={locationLabel}
                                // disabled={true}
                                // value={""}
                                readOnly={readOnly}
                                plainText={readOnly}
                                {...register('locationId')}
                            />
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Account"
                                className="col-sm-3 col-form-label"
                            >
                                Account
                            </CFormLabel>
                            <Controller
                                name="userId"
                                control={control}
                                render={({ field: { onChange, ref } }) => (
                                    <CMultiSelect
                                        ref={ref}
                                        cleaner={true}
                                        options={filteredUsers}
                                        clearSearchOnSelect={true}
                                        multiple={false}
                                        onChange={(options) => {
                                            const option = options[0];
                                            if (!option) return;
                                            const value =
                                                typeof option.value === 'string'
                                                    ? option.value
                                                    : String(option.value);
                                            onChange(value);
                                        }}
                                        searchNoResultsLabel={
                                            <>Please keep typing</>
                                        }
                                        onFilterChange={(value) => {
                                            if (value.length > 2) {
                                                setFilteredUsers(
                                                    users
                                                        ?.filter((u) =>
                                                            u.email?.startsWith(
                                                                value
                                                            )
                                                        )
                                                        .map((user) => ({
                                                            value: user.id,
                                                            text:
                                                                user.email ??
                                                                '',
                                                            selected:
                                                                initialRFID?.userId ===
                                                                user.id,
                                                        })) ?? []
                                                );
                                            }
                                        }}
                                        virtualScroller
                                    />
                                )}
                            />
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel
                                htmlFor="Vehicle"
                                className="col-sm-3 col-form-label"
                            >
                                Vehicle
                            </CFormLabel>
                            <Controller
                                name="vehicleId"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, ref } }) => (
                                    <CMultiSelect
                                        ref={ref}
                                        cleaner={true}
                                        options={vehicleOptions}
                                        multiple={false}
                                        valid={!!errors.vehicleId}
                                        onChange={(options) => {
                                            const option = options[0];
                                            if (!option) return;
                                            const value =
                                                typeof option.value === 'string'
                                                    ? option.value
                                                    : String(option.value);
                                            onChange(value);
                                        }}
                                        virtualScroller
                                    />
                                )}
                            />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="secondary"
                        onClick={() => store.dispatch(setOpen(false))}
                        disabled={loading}
                    >
                        Close
                    </CButton>
                    <CButton color="primary" type="submit" disabled={loading}>
                        Save changes
                    </CButton>
                </CModalFooter>
            </CForm>
        </CModal>
    );
}
