export const toProperCase = function (text: string) {
    return text.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

const pattern = /^([AEIOUaeiou])/i;
// very, very rough calculation of a vs an
export const calculateIndefinite = function (word: string) {
    return pattern.test(word);
};
