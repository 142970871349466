import { useMutation } from '@apollo/client';
import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
} from '@coreui/react';
import { CLoadingButton } from '@coreui/react-pro';
import { loader } from 'graphql.macro';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../../hooks';
import store from '../../../store';
import { addToast } from '../../Toaster/slice';
import { selectIsOpen, selectStation, setOpen } from './slice';
import GatewayStation from '../../../models/GatewayStation';
import StationGateway from '../../../models/StationGateway';

const createGatewayMutation = loader(
    '../../../containers/PropertyPage/Gateway/createGateway.graphql'
);
const updateGatewayMutation = loader('./updateGateway.graphql');

export default function EditGatewayIPAddress() {
    const isOpen = useAppSelector(selectIsOpen);
    const station = useAppSelector(selectStation);
    const gateway = station?.gateway;
    const modalTitle = 'Edit Gateway';

    const { handleSubmit, register, reset } = useForm<GatewayStation>({
        defaultValues: gateway,
    });

    useEffect(() => {
        if (gateway) reset(gateway);
        else reset(undefined);
    }, [gateway, reset]);

    const [mutate, { data, error, loading }] = useMutation(
        gateway ? updateGatewayMutation : createGatewayMutation,
        {
            update: (cache, { data: { createGateway, updateGateway } }) => {
                // keep the Apollo cache up to date without having to fetch the data again
                cache.modify({
                    id: `Station:${station?.id}`,
                    fields: {
                        gateway() {
                            if (gateway) return updateGateway;
                            return createGateway;
                        },
                    },
                });
                cache.modify({
                    fields: {
                        stationGatewayList(
                            cachedGatewayList = [],
                            { readField }
                        ) {
                            const stationId =
                                updateGateway?.id ?? createGateway?.id;
                            const minusCurrentStation =
                                cachedGatewayList.filter(
                                    (csgl: StationGateway) =>
                                        stationId !==
                                        readField('stationId', { ...csgl })
                                );
                            return [
                                ...minusCurrentStation,
                                createGateway,
                                updateGateway,
                            ];
                        },
                    },
                });
            },
        }
    );

    useEffect(() => {
        if (error) {
            store.dispatch(
                addToast({
                    title: `${modalTitle}`,
                    message: error.message,
                    color: 'red',
                })
            );
        }
        if (data) {
            store.dispatch(
                addToast({
                    title: `${modalTitle}`,
                    message: 'Success',
                    color: 'green',
                })
            );
            store.dispatch(setOpen(false));
        }
    }, [error, data, modalTitle]);

    const onSubmit = async (formValues: GatewayStation) =>
        mutate({
            // 'gateway' is for create, 'id' & 'ipv4Address' are for update
            variables: {
                id: gateway?.id,
                ipv4Address: formValues.ipv4Address,
                gateway: {
                    serialNumber: station?.serialNumber,
                    propertyId: station?.propertyId,
                    ipv4Address: formValues.ipv4Address,
                },
            },
        });

    return (
        <CModal
            size="lg"
            visible={isOpen}
            alignment="center"
            onClose={() => store.dispatch(setOpen(false))}
        >
            <CForm onSubmit={handleSubmit(onSubmit)}>
                <CModalHeader>
                    <CModalTitle>{modalTitle}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow lg={{ gutterY: 2 }}>
                        <CCol md={6}>
                            <CFormLabel htmlFor="panel-name">
                                Gateway IP Address
                            </CFormLabel>
                            <CFormInput
                                id="panel-name"
                                placeholder="IP Address"
                                {...register('ipv4Address', {
                                    required: true,
                                })}
                            />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="secondary"
                        onClick={() => store.dispatch(setOpen(false))}
                        disabled={loading}
                    >
                        Close
                    </CButton>
                    <CLoadingButton
                        color="primary"
                        type="submit"
                        loading={loading}
                    >
                        Save changes
                    </CLoadingButton>
                </CModalFooter>
            </CForm>
        </CModal>
    );
}
