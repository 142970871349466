import { useMutation } from '@apollo/client';
import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
} from '@coreui/react';
import { CLoadingButton } from '@coreui/react-pro';
import { loader } from 'graphql.macro';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../../hooks';
import Panel, { convertIntoFormValues } from '../../../models/Panel';
import store from '../../../store';
import { addToast } from '../../Toaster/slice';
import {
    selectInitialPanel,
    selectIsOpen,
    selectLocationId,
    selectProperty,
    selectPropertyId,
    setOpen,
} from './slice';
import { locationLabel } from '../../../utils/labels';

const createPanelMutation = loader('./createPanel.graphql');
const updatePanelMutation = loader('./updatePanel.graphql');

export default function EditPanelModal() {
    const isOpen = useAppSelector(selectIsOpen);
    const propertyId = useAppSelector(selectPropertyId);
    const locationId = useAppSelector(selectLocationId);
    const property = useAppSelector(selectProperty);
    const initialPanel = useAppSelector(selectInitialPanel);
    const modalTitle = initialPanel ? 'Edit Panel' : 'Add Panel';

    const { handleSubmit, register, reset, watch, resetField } = useForm<Panel>(
        {
            defaultValues: {
                propertyId,
                locationId: locationId || '',
                ...initialPanel,
            },
        }
    );

    const watchLocationId = watch('locationId');
    const parentPanels = watchLocationId
        ? property?.locations.find((l) => l.id === watchLocationId)?.panels
        : property?.panels;

    useEffect(() => {
        if (initialPanel)
            reset({
                propertyId,
                locationId: locationId || '',
                ...initialPanel,
            });
        else reset({ propertyId, locationId: locationId || '' });
    }, [initialPanel, locationId, propertyId, reset]);

    const [mutate, { data, error, loading }] = useMutation(
        initialPanel ? updatePanelMutation : createPanelMutation
    );

    useEffect(() => {
        if (error) {
            store.dispatch(
                addToast({
                    title: `${modalTitle} Error`,
                    message: error.message,
                    color: 'red',
                })
            );
        }
        if (data) {
            store.dispatch(
                addToast({
                    title: `${modalTitle} Success`,
                    message: 'Success',
                    color: 'green',
                })
            );
            store.dispatch(setOpen(false));
        }
    }, [error, data, modalTitle]);

    const onSubmit = async (formValues: Panel) =>
        mutate({
            variables: {
                id: initialPanel?.id,
                panel: convertIntoFormValues(formValues),
            },
        });

    return (
        <CModal
            size="lg"
            visible={isOpen}
            alignment="center"
            onClose={() => store.dispatch(setOpen(false))}
        >
            <CForm onSubmit={handleSubmit(onSubmit)}>
                <CModalHeader>
                    <CModalTitle>{modalTitle}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow lg={{ gutterY: 2 }}>
                        <CCol md={6}>
                            <CFormLabel htmlFor="panel-name">Name</CFormLabel>
                            <CFormInput
                                id="panel-name"
                                placeholder="Name"
                                {...register('name', {
                                    required: true,
                                })}
                            />
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel htmlFor="panel-location">
                                {locationLabel}
                            </CFormLabel>
                            <CFormSelect
                                id="panel-location"
                                disabled={!!locationId}
                                {...register('locationId', {
                                    setValueAs: (value) =>
                                        value === 'No Location' ? null : value,
                                    onChange: () => resetField('parentId'),
                                })}
                            >
                                <option value="">No {locationLabel}</option>
                                {(property?.locations || []).map((l) => (
                                    <option value={l.id}>{l.name}</option>
                                ))}
                            </CFormSelect>
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel htmlFor="panel-parent">
                                Parent Panel
                            </CFormLabel>
                            <CFormSelect
                                id="panel-parent"
                                {...register('parentId')}
                            >
                                <option value="">No Parent</option>
                                {(parentPanels || []).map((p) => (
                                    <option value={p.id}>{p.name}</option>
                                ))}
                            </CFormSelect>
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel htmlFor="panel-maxAmps">
                                Max Amps
                            </CFormLabel>
                            <CFormInput
                                placeholder="32"
                                type="number"
                                {...register('maxAmps', {
                                    valueAsNumber: true,
                                })}
                            />
                        </CCol>
                        <CCol md={12}>
                            <CFormLabel htmlFor="panel-description">
                                Description
                            </CFormLabel>
                            <CFormTextarea {...register('description')} />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="secondary"
                        onClick={() => store.dispatch(setOpen(false))}
                        disabled={loading}
                    >
                        Close
                    </CButton>
                    <CLoadingButton
                        color="primary"
                        type="submit"
                        loading={loading}
                    >
                        Save changes
                    </CLoadingButton>
                </CModalFooter>
            </CForm>
        </CModal>
    );
}
