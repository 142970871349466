import '@coreui/coreui-pro/dist/css/coreui.min.css';
import React, { Component, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import 'rsuite/dist/rsuite.min.css';
import Toaster from './components/Toaster';
import Modals from './components/modals';
import './sass/style.sass';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const Layout = React.lazy(() => import('./containers/Layout'));
const Loading = React.lazy(() => import('./containers/Loading'));

// Pages
const Login = React.lazy(() => import('./containers/LoginPage/LoginPage'));
const ForgotPassword = React.lazy(
    () => import('./containers/ForgotPasswordPage/ForgotPasswordPage')
);
const ResetPassword = React.lazy(
    () => import('./containers/ForgotPasswordPage/ResetPasswordPage')
);
// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
    render() {
        return (
            <Suspense fallback={loading}>
                <Toaster />
                <Modals />
                <Loading />
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/forgotPassword"
                        element={<ForgotPassword />}
                    />
                    <Route path="/resetPassword" element={<ResetPassword />} />
                    {/* <Route exact path="/register" name="Register Page" element={<Register />} />
        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
                    <Route path="*" element={<Layout />} />
                </Routes>
            </Suspense>
        );
    }
}

export default App;
