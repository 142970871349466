import { pick } from '../utils/helper';
import ItemStatus from './enums/ItemStatus';
import Location from './Location';
import Organization from './Organization';
import Property from './Property';
import Vehicle from './Vehicle';
import User from './User';

export default interface RFID {
    __typename: 'RFID';
    id: string;
    rawRfid: number;
    rfid: string;
    userId: string;
    user?: User;
    inactivatedAt?: Date;
    createdAt: Date;
    itemStatus: ItemStatus;
    nickname?: string;
    lastUsed?: Date;
    organization?: Organization;
    organizationId?: string;
    property?: Property;
    propertyId: string;
    location?: Location;
    locationId?: string;
    vehicle?: Vehicle;
    vehicleId?: string;
}

export interface RFIDFormValues
    extends Omit<
        RFID,
        | '__typename'
        | 'id'
        | 'user'
        | 'organization'
        | 'property'
        | 'itemStatus'
        | 'rfid'
        | 'user'
        | 'inactivatedAt'
        | 'createdAt'
        | 'nickname'
        | 'lastUsed'
        | 'vehicle'
        | 'location'
    > {}

// View ./Organization.ts for explanation on this
const rfidFormKeys = [
    'rawRfid',
    'userId',
    'organizationId',
    'propertyId',
    'locationId',
    'vehicleId',
] as const;

// the following line will error if rfidFormKeys has entries not in keyof RFIDFormValues:
type ExtraRFIDFormKeysWarning<
    T extends never = Exclude<
        (typeof rfidFormKeys)[number],
        keyof RFIDFormValues
    >
> = void;
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Type 'UNION_OF_EXTRA_KEY_NAMES_HERE' does not satisfy the constraint 'never'

// the following line will error if rfidFormKeys is missing entries from keyof RFIDFormValues:
type MissingRFIDFormKeysWarning<
    T extends never = Exclude<
        keyof RFIDFormValues,
        (typeof rfidFormKeys)[number]
    >
> = void;
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Type 'UNION_OF_MISSING_KEY_NAMES_HERE' does not satisfy the constraint 'never'

export const convertIntoFormValues = (
    rfid: RFID | RFIDFormValues
): RFIDFormValues => {
    const formValues = pick(rfid, ...rfidFormKeys);
    formValues.rawRfid = parseInt(formValues.rawRfid as any as string);
    return formValues;
};

export type ExtendedUserRFID = RFID & {
    connFormatted: string;
    barColor: string;
    inFormatted: string | null;
};

type RFIDsResponse = {
    rfids: Array<RFID>;
};

export const extractRFIDs = (data: RFIDsResponse) => {
    if (!data || !data.rfids) return [];
    return data.rfids.map((rfid) => {
        return {
            ...rfid,
            createdAt: new Date(rfid.createdAt),
            inactivatedAt: rfid.inactivatedAt
                ? new Date(rfid.inactivatedAt)
                : undefined,
            lastUsed: rfid.lastUsed ? new Date(rfid.lastUsed) : undefined,
        };
    });
};
