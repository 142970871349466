import { pick } from '../utils/helper';
import Cluster from './Cluster';
import ItemStatus from './enums/ItemStatus';
import Property from './Property';

export default interface Panel {
    id: string;
    name?: string;
    description?: string;
    maxAmps?: number;
    parent?: Panel;
    parentId?: string;
    property: Property;
    propertyId?: string;
    createdAt: Date;
    itemStatus: ItemStatus;
    clusters: Cluster[];
    locationId?: string | null;
}

export interface PanelFormValues
    extends Omit<
        Panel,
        | 'id'
        | 'parent'
        | 'property'
        | 'createdAt'
        | 'clusters'
        | 'itemStatus'
        | '__typename'
    > {}

const formKeys = [
    'name',
    'description',
    'maxAmps',
    'parentId',
    'propertyId',
    'locationId',
] as const;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ExtraFormKeysWarning<
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    T extends never = Exclude<(typeof formKeys)[number], keyof PanelFormValues>
> = void;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type MissingFormKeysWarning<
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    T extends never = Exclude<keyof PanelFormValues, (typeof formKeys)[number]>
> = void;

export const convertIntoFormValues = (
    panel: Panel | PanelFormValues
): PanelFormValues => {
    const formValues = pick(panel, ...formKeys);
    return Object.keys(formValues).reduce((acc: any, key) => {
        let value = formValues[key as keyof PanelFormValues];
        switch (key as keyof PanelFormValues) {
            case 'locationId':
                if (value === '') value = null;
                break;
        }
        acc[key] = value;

        return acc;
    }, {});
};

type CreatePanelResult = {
    createPanel: Panel;
};

export const extractCreatePanel = (data: CreatePanelResult) =>
    data?.createPanel;
